import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdCloudUpload } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
import Sidebar from "../Sidebar";
import Layout from "../Layout";
import "../../index.css";
import { useQuery, gql, useMutation } from "@apollo/client";
import axios from "axios";
import Loader from "../../Loader";
const GET_CATEGORY = gql`
  query {
    getCategory {
      status
      message
      categoryList {
        id
        name
      }
    }
  }
`;

const ADD_PRODUCT = gql`
  mutation addProduct($data: productInput!) {
    addProduct(data: $data) {
      status
      message
    }
  }
`;

const AddSubCategory = () => {
  const navigate = useNavigate();
  const {
    data: otherData,
    loading: otherLoading,
    error: otherError,
  } = useQuery(GET_CATEGORY);

  const [addProduct, { addLoading, addError }] = useMutation(ADD_PRODUCT);
  const [name, setName] = useState("");

  const [category, setCategory] = useState("");

  const [image, setImage] = useState(null);

  const [dataCategoryList, setDataCategoryList] = useState([]);

  const [isFetched, setIsFetched] = useState(false);
  const [description, SetDesciption] = useState("");
  const [gender, setGender] = useState("");
  const genderList = ["Male", "Female"];
  const fileInputRef = useRef(null);

  useEffect(() => {
    try {
      console.log(otherData.getCategory);
      setDataCategoryList(otherData.getCategory.categoryList);
      console.log("setDataCategoryList hitted");
      setIsFetched(true);
    } catch (err) {
      console.log(err);
    }
  }, [otherData]);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    // setLicenseImage(file);
    e.preventDefault();
    const url = `https://api.barbera.in/img/upload`;
    try {
      const formData = new FormData();

      formData.append("file", file);

      const { data } = await axios.post(url, formData);
      console.log(data);
      if (data.status === "Success") {
        setImage(data.url);
        // setLicenseImage(data.url);
      }
    } catch (error) {
      console.error(error);
      window.alert(error.message + " Try Again");
      // history.push("/dashboard/compliance");
    }
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const onCategoryChange = (e) => {
    setCategory(e.target.value);
    console.log(e.target.value);
    // refetch({data: e.target.value}).then((res)=>{
    //   console.log('onCategoryChange hitted');
    //   console.log(res);
    //   if(res.data.getProductByCategoryId.status === "SUCCESS"){
    //   //   window.location.reload();
    //   setProductList(res.data.getProductByCategoryId.products);
    //   }
    // }).catch(err=>{
    //   console.log(err);
    // })
  };
  const handleSubmit = (e) => {
    // setOpenServiceForm(false);
    // console.log(name, price, category, subCategory, inventoryList, description);
    e.preventDefault();
    let finalDataObj = {};
    finalDataObj.name = name;
    finalDataObj.imageURL = image;
    finalDataObj.description = description;
    finalDataObj.categoryId = category;
    finalDataObj.gender = gender;
    console.log(finalDataObj);
    addProduct({
      variables: {
        data: finalDataObj,
      },
    })
      .then((res) => {
        console.log("add service res is");
        console.log(res);
        if (res.data.addProduct.status === "SUCCESS") {
          // window.location.reload();
          window.location = "/admin/sub-category";
          // navigate("/admin/sub-category");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const subCategoryClass = subCategory ? "text-white" : " text-[#9A9A9A]";
  const categoryClass = category ? "text-white" : " text-[#9A9A9A]";
  if (otherLoading || addLoading || !isFetched) return <Loader />;
  return (
    <>
      <div className=" h-screen flex">
        <Sidebar />
        <Layout>
          {isFetched && (
            <div className="flex flex-col py-3 px-2 space-y-5">
              <h3 className="text-[#FBB033] font-be-jost-pro font-bold text-2xl">
                Add Sub Category
              </h3>
              <div className="w-full flex flex-col space-y-8">
                <input
                  type="text"
                  placeholder="Name*"
                  className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <select
                  placeholder="Category*"
                  className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none ${categoryClass}`}
                  value={category}
                  onChange={onCategoryChange}
                >
                  <option value="" disabled hidden>
                    Category*
                  </option>

                  {dataCategoryList.map((item, i) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
                <select
                  placeholder="Category*"
                  className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none ${
                    gender === "" ? " text-[#9A9A9A]" : "text-white"
                  }`}
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="" disabled hidden>
                    Select Gender*
                  </option>

                  {genderList.map((item, i) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
                <div className="flex items-center">
                  <input
                    id="image-upload"
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={handleImageUpload}
                  />
                  <input
                    type="text"
                    placeholder="Upload Image*"
                    disabled={true}
                    className="w-4/5 px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
                  />
                  <IoMdCloudUpload
                    className="text-[#9A9A9A] text-3xl"
                    onClick={handleBrowseClick}
                  />
                  <p className="ml-3 font-be-jost-pro text-[#FBB033] font-normal text-base cursor-pointer">
                    Mark as Primary
                  </p>
                </div>

                {/* {inventoryList.map((inventory, index) => {
          return (
            <div className="flex space-x-2 lg:space-x-8" key={index}>
              <select
                className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none ${
                  inventory.inventory === "" ? " text-[#9A9A9A]" : "text-white"
                } w-1/2 lg:w-[25%]`}
                value={inventory.inventoryId}
                onChange={(e) => handleInventoryChange(index, e)}
                name="inventoryId"
              >
                <option value="" disabled hidden>
                  Inventory*
                </option>
            
                {
                  dataList.map((item,i)=>(
                    <option value={item.id} key={i}>{item.name}</option>
                  ))
                }
              </select>
              <div className="flex items-center">
                <span
                  className="text-[#FBB033] font-be-jost-pro text-base font-normal cursor-pointer"
                  onClick={handleAddRow}
                >
                  {`Add Inventory +  `}
                </span>
                {inventoryList.length !== 1 && (
                  <AiOutlineDelete
                    className="text-[#FBB033] text-2xl cursor-pointer"
                    onClick={() => handleRemoveRow(index)}
                  />
                )}
              </div>
              <input
                type="text"
                placeholder="Amount*"
                className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-1/3"
                value={inventory.price}
                onChange={(event) => handleInventoryPriceChange(index, event)}
                name="price"
              />
            </div>
          );
        })} */}

                <input
                  type="text"
                  placeholder="Description*"
                  className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
                  value={description}
                  onChange={(e) => SetDesciption(e.target.value)}
                />
                <div className="flex items-center justify-center">
                  <button
                    className="bg-[#FBB034] text-[#202125] py-2 px-6 rounded-md font-poppins text-sm font-bold"
                    onClick={handleSubmit}
                  >
                    Add Sub Category
                  </button>
                </div>
              </div>
            </div>
          )}
        </Layout>
      </div>
    </>
  );
};

export default AddSubCategory;
