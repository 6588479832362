import { React, useState } from "react";
import Switch from "react-switch";
import DataTable from "../DataTable";
import "../../index.css";
import ServiceEditForm from "./ServiceEditForm";
import { useLocation, Link } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
const DELETE_SERVICE = gql`
  mutation deleteService($data: ID!) {
    deleteService(data: $data) {
      status
      message
    }
  }
`;

// const GET_FEATUREDCOUNT = gql`
//   query {
//     checkedFeaturedService {
//       status
//       message
//       count
//     }
//   }
// `;

// const FEATURED_STATUS = gql`
//   mutation updateFeaturedService($data: featuredActive!) {
//     updateFeaturedService(data: $data) {
//       status
//       message
//     }
//   }
// `;

const ACTIVE_STATUS = gql`
  mutation updateService($data: UpdateServiceInput!) {
    updateService(data: $data) {
      status
      message
    }
  }
`;

const ServiceTable = ({ data, setOpenServiceForm, serviceList }) => {
  const location = useLocation();

  const [deleteService, { deleteLoading, deletError }] =
    useMutation(DELETE_SERVICE);
  // const {
  //   loading: previousLoading,
  //   error: previousError,
  //   refetch,
  // } = useQuery(GET_FEATUREDCOUNT);
  // const [changeFeaturedStatus, { error: statusError, loading: statusLoading }] =
  //   useMutation(FEATURED_STATUS);

  const [changeActiveStatus,{error:activestatusError,loading:activestatusLoading}]=useMutation(ACTIVE_STATUS);


  const [isEmployeeManagement, setIsEmployeeManagement] = useState(true);
  const [editForm, setEditForm] = useState(false);
  const [editData, setEditData] = useState({});
  console.log("ServiceTable");
  console.log(serviceList);

  const handleRemoveRow = (dataId) => {
    //let con=confirm("are you sure you want to delete")
    console.log(dataId);
    deleteService({
      variables: { data: dataId },
    }).then((res) => {
      // window
      console.log(" delete res is");
      console.log(res);
      if (res.data.deleteService.status === "SUCCESS") {
        window.location.reload();
      }
    });
  };

  const handleEditClick = (data) => {
    setEditData(data);

    setEditForm(true);
  };

  return (
    <>
      <div className="flex flex-col space-y-6">
        <div className="flex justify-center xl:justify-between   text-[#FBB033] items-center font-be-jost-pro text-xl font-bold space-x-3 pt-8 pl-5">
          <div className="flex text-[#FBB033] items-center font-be-jost-pro text-xl font-bold space-x-3 pt-8 pl-5">
            <p
              className={`${
                isEmployeeManagement
                  ? "bg-white text-[#00395E]"
                  : "text-[#FBB034]"
              }  hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left`}
              onClick={() => setIsEmployeeManagement(true)}
            >
              Services
            </p>
            {/* <p
                    className={`${
                      !isEmployeeManagement
                        ? "bg-white text-[#00395E]"
                        : "text-[#FBB034]"
                    }    hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left`}
                    onClick={() => setIsEmployeeManagement(false)}
                  >
                    Sub Category
                  </p> */}
             <Link
              to="/admin/category"
              className="text-[#FBB034] hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left"
            >
              <span>Category</span>
            </Link>
            <Link
              to="/admin/sub-category"
              className="text-[#FBB034] hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left"
            >
              <span>Sub Category</span>
            </Link>

            <Link
              to="/admin/inventory-table"
              className="text-[#FBB034] hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left"
            >
              <span>Inventory</span>
            </Link>
          </div>

          <button
            className="border border-[#ab997b] bg-black text-[#FBB033] font-be-jost-pro font-medium text-sm rounded-md px-10 py-2"
            onClick={() => setOpenServiceForm(true)}
          >
            Add Services
          </button>
        </div>
        {/* <div className="overflow-x-auto"> */}
        {editForm ? (
          <ServiceEditForm editFormData={editData} />
        ) : (
          <div>
            <DataTable tableName="Services">
              <thead>
                <tr>
                  <th data-priority="1" className="text-white">
                    Service Name
                  </th>
                  <th data-priority="2" className="text-white">
                    Category
                  </th>
                  <th data-priority="3" className="text-white">
                    Sub-Category
                  </th>
                  <th data-priority="4" className="text-white">
                    Price
                  </th>
                  {/* <th data-priority="5" className="text-white">
                Add Image
              </th> */}
                  {/* <th data-priority="6" className="text-white">
                Inventory
              </th> */}
                  <th data-priority="7" className="text-white">
                    Time
                  </th>
                  {/* <th data-priority="7" className="text-white">
                    Is Featured
                  </th> */}
                  <th data-priority="7" className="text-white">
                    Is Active
                  </th>
                  {/* <th data-priority="8" className="text-white">
                    Desciption
                  </th> */}
                  <th data-priority="9" className="text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {serviceList.map((product,i) => {
                  return (
                    <tr key={i} className="border-b-2 border-b-[#E0E0E0]" >
                      <td className="text-white w-[10%] px-1 border-b-[1px] border-b-[#E0E0E0]">
                        {product.name}
                      </td>
                      <td className="w-[8%] border-b-[1px] border-b-[#E0E0E0]">
                        <div className="bg-[#FBB033] rounded-2xl px-3 py-1 flex justify-center w-3/5">
                          <p className="uppercase text-xs font-medium text-black ">{`${product.serviceCategory.name}`}</p>
                        </div>
                      </td>
                      <td className="w-[8%] border-b-[1px] border-b-[#E0E0E0]">
                        <div className="bg-[#FBB033] rounded-2xl px-3 py-1 flex justify-center w-3/5">
                          <p className="uppercase text-xs font-medium text-black ">{`${product.product.name}`}</p>
                        </div>
                      </td>
                      <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">{`₹  ${product.price}`}</td>

                      <td className="w-[8%] text-center border-b-[1px] border-b-[#E0E0E0]">
                        {/* {product.inventory.map((obj) => (
                      <p className="text-white">{`x${obj.amount}`}</p>
                    ))} */}
                        {`${product.time}`}
                      </td>
                      
                      {/* <td className="text-white w-[22%] border-b-[1px] border-b-[#E0E0E0]">
                        {product.description}
                      </td> */}

                      <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">
                        
                        <Switch
                          checked={product.isActive}
                          onChange={(e) =>{
                            changeActiveStatus({
                                  variables: {
                                    data: {
                                      id: product.id,
                                      isActive: !product.isActive,
                                    },
                                  },
                                }).then((res) => {
                                  // window
                                  console.log(" delete res is");
                                  console.log(res);
                                  if (
                                    res.data.updateService.status ===
                                    "SUCCESS"
                                  ) {
                                     window.location.reload();
                                  }
                                }).catch((error) => {
                                  console.error("Error updating status:", error);
                                });
                              }  
                            }
                          
                          onColor="#86d3ff"
                          onHandleColor="#2693e6"
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          offColor="#ffffff"
                          offHandleColor="#fbb033"
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={48}
                          className="react-switch"
                          id={product.id}
                        />
                      </td>

                      <td className="w-[10%] text-center px-2 border-b-[1px] border-b-[#E0E0E0]">
                        <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-center justify-center h-full lg:space-x-2">
                          <button
                            className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium"
                            onClick={() => handleEditClick(product)}
                          >
                            Edit
                          </button>
                          <button
                            className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium"
                            onClick={() => handleRemoveRow(product.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </DataTable>
          </div>
        )}
      </div>
    </>
  );
};

export default ServiceTable;
