import { useState } from "react";
import Carrer from "./Carrer";
import Navbar from "./Components/Navbar";
import ContactUs from "./ContactUs";
import Cookie from "./Cookie";
import GetApp from "./GetApp";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Services from "./Services";
import Footer from "./Components/Footer";
import Home from "./Home";
import About from "./About";
import AdminLogin from "./AdminLogin";
import AdminDashboard from "./AdminDashboard";
import AdminService from "./AdminService";
import AdminBookings from "./AdminBookings";
import Banners from "./Banners";
import CodeManagement from "./CodeManagement";
import UserManagement from "./UserManagement";
import ProtectedRoute from "./Routings/protectedRoutes";
import SubCategoryTable from "./Components/sub-category/SubCategoryTable";
import AddSubCategory from "./Components/sub-category/AddSubCategory";
import InventoryTable from "./Components/inventory/InventoryTable";
import AddInventory from "./Components/inventory/AddInventory";
import PreviousBooking from "./Components/AdminBookingComponent/previousBooking";
import UserDashboard from "./UserDashboard";
import EmployeeDashboard from "./EmployeeDashboard";
import CategoryTable from "./Components/category/CategoryTable";
import AddCategory from "./Components/category/AddCategory";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<ContactUs />} />
        <Route exact path="/career" element={<Carrer />} />
        <Route exact path="/getApp" element={<GetApp />} />
        <Route exact path="/cookie" element={<Cookie />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/terms&conditions" element={<Terms />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/admin/login" element={<AdminLogin />} />
        <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
        <Route exact path="/.well-known/" element={<AdminDashboard />} />
        <Route
          exact
          path="/admin/services"
          element={
            <ProtectedRoute>
              <AdminService />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/bookings"
          element={
            <ProtectedRoute>
              <AdminBookings />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/user-management"
          element={
            <ProtectedRoute>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/user-dashboard/:userId"
          element={
            <ProtectedRoute>
              <UserDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/employee-dashboard/:employeeId"
          element={
            <ProtectedRoute>
              <EmployeeDashboard />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/admin/code-management"
          element={
            <ProtectedRoute>
              <CodeManagement />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/banners"
          element={
            <ProtectedRoute>
              <Banners />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/admin/category"
          element={
            <ProtectedRoute>
              <CategoryTable />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/admin/sub-category"
          element={
            <ProtectedRoute>
              <SubCategoryTable />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/admin/create/sub-category"
          element={
            <ProtectedRoute>
              <AddSubCategory />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/admin/inventory-table"
          element={
            <ProtectedRoute>
              <InventoryTable />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/admin/inventory/create"
          element={
            <ProtectedRoute>
              <AddInventory />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/admin/previous/booking"
          element={
            <ProtectedRoute>
              <PreviousBooking />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin/create/category"
          element={
            <ProtectedRoute>
              <AddCategory />
            </ProtectedRoute>
          }
        />
      </Routes>

      <Footer />
      {/* <TempNav /> */}
      {/* <GetApp /> */}
      {/* <Carrer /> */}
      {/* <ContactUs /> */}
    </div>
  );
}

export default App;
