import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdCloudUpload } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
import Sidebar from "../Sidebar";
import Layout from "../Layout";
import "../../index.css";
import { useQuery, gql, useMutation } from "@apollo/client";
import axios from "axios";
import Loader from "../../Loader";

const ADD_CATEGORY = gql`
  mutation addCategory($data: EntryInput!) {
    addCategory(data: $data) {
      status
      message
    }
  }
`;

const AddCategory = () => {
  const navigate = useNavigate();

  const [addCategory, { addLoading, addError }] = useMutation(ADD_CATEGORY);

  const [category, setCategory] = useState("");

  const [image, setImage] = useState(null);

  const [isFetched, setIsFetched] = useState(true);
  const [description, SetDesciption] = useState("");
  const [gender, setGender] = useState("");
  const genderList = ["Male", "Female"];

  const fileInputRef = useRef(null);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    // setLicenseImage(file);
    e.preventDefault();
    const url = `https://api.barbera.in/img/upload`;
    try {
      const formData = new FormData();

      formData.append("file", file);

      const { data } = await axios.post(url, formData);
      console.log(data);
      if (data.status === "Success") {
        setImage(data.url);
        // setLicenseImage(data.url);
      }
    } catch (error) {
      console.error(error);
      window.alert(error.message + " Try Again");
      // history.push("/dashboard/compliance");
    }
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = (e) => {
    // setOpenServiceForm(false);
    // console.log(name, price, category, subCategory, inventoryList, description);
    e.preventDefault();
    let finalDataObj = {};

    finalDataObj.imageURL = image;
    finalDataObj.description = description;
    finalDataObj.name = category;
    finalDataObj.gender = gender;
    console.log(finalDataObj);
    addCategory({
      variables: {
        data: finalDataObj,
      },
    })
      .then((res) => {
        console.log("add service res is");
        console.log(res);
        if (res.data.addCategory.status === "SUCCESS") {
          // window.location.reload();
          window.location = "/admin/category";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (addLoading || !isFetched) return <Loader />;
  return (
    <>
      <div className=" h-screen flex">
        <Sidebar />
        <Layout>
          {isFetched && (
            <div className="flex flex-col py-3 px-2 space-y-5">
              <h3 className="text-[#FBB033] font-be-jost-pro font-bold text-2xl">
                Add Category
              </h3>
              <div className="w-full flex flex-col space-y-8">
                <input
                  type="text"
                  placeholder="Category_Name*"
                  className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />

                <div className="flex items-center">
                  <input
                    id="image-upload"
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={handleImageUpload}
                  />
                  <input
                    type="text"
                    placeholder="Upload Image*"
                    disabled={true}
                    className="w-4/5 px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
                  />
                  <IoMdCloudUpload
                    className="text-[#9A9A9A] text-3xl"
                    onClick={handleBrowseClick}
                  />
                  <p className="ml-3 font-be-jost-pro text-[#FBB033] font-normal text-base cursor-pointer">
                    Mark as Primary
                  </p>
                </div>

                <select
                  placeholder="Category*"
                  className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none ${
                    gender === "" ? " text-[#9A9A9A]" : "text-white"
                  }`}
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="" disabled hidden>
                    Select Gender*
                  </option>

                  {genderList.map((item, i) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>

                <input
                  type="text"
                  placeholder="Description*"
                  className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
                  value={description}
                  onChange={(e) => SetDesciption(e.target.value)}
                />
                <div className="flex items-center justify-center">
                  <button
                    className="bg-[#FBB034] text-[#202125] py-2 px-6 rounded-md font-poppins text-sm font-bold"
                    onClick={handleSubmit}
                  >
                    Add Category
                  </button>
                </div>
              </div>
            </div>
          )}
        </Layout>
      </div>
    </>
  );
};

export default AddCategory;
