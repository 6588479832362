import React, { useState, useEffect } from "react";
import DataTable from "../DataTable";
// import AddPromoModal from "./AddPromoModal";
import { useLocation, Link } from "react-router-dom";
import "../../index.css";
import Sidebar from "../Sidebar";
import Layout from "../Layout";
import EditCategory from "./editCategory";
import { gql, useMutation, useQuery } from "@apollo/client";
import Loader from "../../Loader";
import Switch from "react-switch";
const DELETE_CATEGORY = gql`
  mutation deleteCategory($data: ID!) {
    deleteCategory(data: $data) {
      status
      message
    }
  }
`;

const GET_CATEGORY = gql`
  query {
    getCategory {
      status
      message
      categoryList {
        id
        name
        isActive
        gender
        description
        imageURL
      }
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation updateCategory($data: updateCategoryInput!) {
    updateCategory(data: $data) {
      status
      message
    }
  }
`;

const CategoryTable = () => {
  const { data: otherData, loading, error } = useQuery(GET_CATEGORY);
  const [openModal, setOpenModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [isFetched, setIsFetched] = useState(false);

  const [deleteCategory, { loading: prodDeletLoading, error: producterror }] =
    useMutation(DELETE_CATEGORY);
  const [updateCategory,{updateLoading,updateError}]=useMutation(UPDATE_CATEGORY);

  const [editForm, setEditForm] = useState(false);
  const [editData, setEditData] = useState({});
  useEffect(() => {
    try {
      setDataList(otherData.getCategory.categoryList);
      setIsFetched(true);
      console.log(otherData.getCategory.categoryList);
      console.log("heyy i am category above");
    } catch (err) {
      console.log(err);
    }
  }, [otherData]);
  // const [deletePromocodeMutation, {loading, error}]= useMutation(DELETE_PROMOCODE,
  //   {
  //     variables:{
  //       id: id
  //     }
  //   })
  // function deletePromocodeMutation(event) {
  //   let dataId= event.target.getAttribute("data-deleteId")
  //   alert(dataId);
  //   const { data, loading, error } = useMutation(DELETE_PROMOCODE, {
  //           variables:{
  //             id: dataId
  //           }
  //   });

  const handleRemoveRow = (dataId) => {
    console.log(dataId);
    deleteCategory({
      variables: { data: dataId },
    }).then((res) => {
      // window
      console.log(" delete res is");
      console.log(res);
      if (res.data.deleteCategory.status === "SUCCESS") {
        window.location.reload();
      }
    });
  };

  const handleEditClick = (data) => {
    setEditData(data);

    setEditForm(true);
  };
  if (loading || prodDeletLoading || !isFetched) return <Loader />;
  return (
    <>
      {isFetched ? (
        editForm ? (
          <EditCategory editFormData={editData} />
        ) : (
          <div className=" h-screen flex">
            <Sidebar />
            <Layout>
              <div className="flex flex-col space-y-6 pt-8 ">
                <div className="flex justify-between items-center">
                  <div className="flex text-[#FBB033] items-center font-be-jost-pro text-xl font-bold space-x-3 pt-8 pl-5">
                    <Link
                      to="/admin/services"
                      className="text-[#FBB034] hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left"
                    >
                      <span> Services</span>
                    </Link>
                    <Link
                      to="/admin/category"
                      className=" rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left bg-white text-[#00395E]"
                    >
                      <span>Category</span>
                    </Link>

                    <Link
                      to="/admin/sub-category"
                      className="text-[#FBB034] hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left"
                    >
                      <span>Sub Category</span>
                    </Link>
                    <Link
                      to="/admin/inventory-table"
                      className="text-[#FBB034] hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left"
                    >
                      Inventory
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="/admin/create/category"
                      className="font-be-jost-pro font-medium text-sm border border-[#FBB033] text-[#FBB033] rounded-md px-5 py-2"
                    >
                      Add Category
                    </Link>
                  </div>
                </div>
                <div>
                  <DataTable tableName="Category">
                    <thead>
                      <tr>
                        <th data-priority="1" className="text-white">
                          Name
                        </th>
                        <th data-priority="2" className="text-white">
                          isActive
                        </th>
                        <th data-priority="5" className="text-white">
                          Actions
                        </th>
                        <th data-priority="4" className="text-white">
                          image
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataList.map((row) => {
                        return (
                          <tr className="border-b-2 border-b-[#E0E0E0]">
                            <td className="text-white w-[10%] px-1 border-b-[1px] border-b-[#E0E0E0]">
                              {row.name}
                            </td>

                            <td className="text-white w-[10%] px-1 border-b-[1px] border-b-[#E0E0E0]">
                              <Switch
                                checked={row.isActive}
                                onChange={(e)=>{
                                  updateCategory({
                                    variables:{
                                      data:{
                                        id:row.id,
                                        isActive:!row.isActive,
                                      },
                                    },
                                  }).then((res)=>{
                                    if(res.data.updateCategory.status==="SUCCESS"){
                                      window.location.reload();
                                    }
                                  }).catch((err)=>{
                                    console.log("Error updating category status:",err);
                                  });
                                }
                              }
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                offColor="#ffffff"
                                offHandleColor="#fbb033"
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                              />
                            </td>
                            <td className="w-[10%] text-center px-2 border-b-[1px] border-b-[#E0E0E0]">
                              <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-center justify-center h-full lg:space-x-2">
                                <button
                                  className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium"
                                  onClick={() => handleEditClick(row)}
                                >
                                  Edit
                                </button>
                                <button
                                  //  onClick={e=> {e.preventDefault(); deleteProduct({ variables:{ id: row.id}});window.location.reload() }} data-deleteId= {row.id}
                                  onClick={() => handleRemoveRow(row.id)}
                                  className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium"
                                >
                                  Delete
                                </button>
                              </div>
                            </td>
                            <td className="text-white w-[10%] px-1 border-b-[1px] border-b-[#E0E0E0]">
                            <img src={`https://api.barbera.in/graphql${row.imageURL}`} alt="new" className="w-20 h-20 object-fit" />
                            

                            </td>
                            {/* <td className="w-[8%] border-b-[1px] border-b-[#E0E0E0]">
                              <div className="bg-[#FBB033] rounded-2xl px-3 py-1 flex justify-center w-3/5">
                                <p className="uppercase text-xs font-medium text-black ">
                                  {row.category.name}
                                </p>
                              </div>
                            </td> */}
                            {/* <td className="w-[10%] text-center px-2 border-b-[1px] border-b-[#E0E0E0]">
                              <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-center justify-center h-full lg:space-x-2">
                                <button
                                  className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium"
                                  onClick={() => handleEditClick(row)}
                                >
                                  Edit
                                </button>
                                <button
                                  //  onClick={e=> {e.preventDefault(); deleteProduct({ variables:{ id: row.id}});window.location.reload() }} data-deleteId= {row.id}
                                  onClick={() => handleRemoveRow(row.id)}
                                  className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium"
                                >
                                  Delete
                                </button>
                              </div>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </DataTable>
                </div>
                {/* {openModal && <AddPromoModal setShowModal={setOpenModal} />} */}
              </div>
            </Layout>
          </div>
        )
      ) : (
        <div className=" h-screen flex">
          <Sidebar />
        </div>
      )}
    </>
  );
};

export default CategoryTable;
