import React, { useState, useEffect } from "react";
import Sidebar from "./Components/Sidebar";
import Layout from "./Components/Layout";
// import DataTable from "react-data-table-component";
import DataTable from "./Components/DataTable";
import "./index.css";
import CodeManagementTable from "./Components/CodeManagament/CodeManagementTable";
//import CodeManagementForm from "./Components/CodeManagament/CodeManagementForm";
import AddPromoModal from "./Components/CodeManagament/AddPromoModal";
import { useQuery, gql } from "@apollo/client";
import Loader from "./Loader";
const GET_PROMOCODE = gql`
  query {
    getPromocode {
      status
      message
      promoCodeList {
        id
        name
        promocode
        discount
        type
        description
        users
        minOrder
        maxDiscount
        orderNo
        validFrom
        validTo
        serviceId
        isPercentage
        isActive
        hide
        gender
        isServicePromo
      }
    }
  }
`;

const tempData = [
  {
    name: "New User",
    type: "New User",
    off: "20",
    promoCode: "new001",
  },
  {
    name: "HDFC Card",
    type: "Credit Card",
    off: "10",
    promoCode: "Barbera001",
  },
  {
    name: "Discount 50",
    type: "Discount",
    off: "25",
    promoCode: "Discount25",
  },
  {
    name: "Discount 50",
    type: "Discount",
    off: "25",
    promoCode: "Discount25",
  },
  {
    name: "Discount 50",
    type: "Discount",
    off: "25",
    promoCode: "Discount25",
  },
  {
    name: "Discount 50",
    type: "Discount",
    off: "25",
    promoCode: "Discount25",
  },
];

const CodeManagement = () => {
  // const promocodes = useQuery(GET_PROMOCODE);
  const { data, loading, error } = useQuery(GET_PROMOCODE);
  // console.log('data is');
  // console.log(promocodes);
  // console.log(promocodes.error,promocodes.loading,promocodes.data);
  // console.log(promocodes.data.getPromocode.promoCodeList);
  const [dataList, setDataList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    try {
      console.log("data is");
      console.log(data);

      setDataList(data.getPromocode.promoCodeList);
      setIsFetched(true);
    } catch (err) {
      console.log(err);
    }
  }, [data]);
  if (loading) return <Loader />;
  return (
    <>
      {isFetched && (
        <div className=" h-screen flex">
          <Sidebar />
          <Layout>
            {openModal ? (
              <AddPromoModal setOpenModal={setOpenModal} />
            ) : (
              <CodeManagementTable
                data={dataList}
                setOpenModal={setOpenModal}
              />
            )}
          </Layout>
        </div>
      )}
    </>
  );
};

export default CodeManagement;
