import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "../DataTable";
import "../../index.css";
import { useQuery, gql, useMutation } from "@apollo/client";
import Switch from "react-switch";
import Loader from "../../Loader";
const ADD_PROMOCODE = gql`
  mutation CreatePromocode($data: promocodeInput) {
    addPromocode(data: $data) {
      status
      message
    }
  }
`;
const GET_SERVICES = gql`
  query {
    getAdminService {
      services {
        id
        name
      }
    }
  }
`;

const AddPromoModal = ({ setOpenModal }) => {
  const { data, loading, error } = useQuery(GET_SERVICES);

  const [addPromocode, { addLoading, addError }] = useMutation(ADD_PROMOCODE);

  const [isFetched, setIsFetched] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [discount, setdiscount] = useState("");
  const [promocode, setPromocode] = useState("");
  const [description, setDescription] = useState("");
  const [users, setUsers] = useState("");
  const [minorder, setMinOrder] = useState("");
  const [maxdiscount, setMaxDiscount] = useState("");
  const [orderNo, setorderNo] = useState("");

  const [validfrom, setValidFromDate] = useState("");
  const [validto, setValidToDate] = useState("");

  const [service, setService] = useState("");
  const [serviceList, setServiceList] = useState([]);

  const [gender, setGender] = useState("");
  const genderList = ["Male", "Female"];

  const [isPercentage, setIsPercentage] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [isHide, setIsHide] = useState(true);
  const [isServicePromo, setIsServicePromo] = useState(true);

  useEffect(() => {
    if (data?.getAdminService?.services) {
      console.log("get service called");
      // console.log(data.getAdminService.services);
      setServiceList(data.getAdminService.services);
      setIsFetched(true);
    }
  }, [data]);

  // const onCategoryChange = (e) => {
  //   setCategory(e.target.value);
  //   console.log(e.target.value);
  //   refetch({ data: e.target.value })
  //     .then((res) => {
  //       console.log("onCategoryChange hitted");
  //       console.log(res);
  //       if (res.data.getProductByCategoryId.status === "SUCCESS") {
  //         //   window.location.reload();
  //         setProductList(res.data.getProductByCategoryId.products);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    let finalDataObj = {};
    finalDataObj.name = name;
    if (type !== "") finalDataObj.type = type;
    if (discount !== "") finalDataObj.discount = parseInt(discount);
    if (promocode !== "") finalDataObj.promocode = promocode;
    if (description !== "") finalDataObj.description = description;
    if (users !== "") finalDataObj.users = parseInt(users);
    if (minorder !== "") finalDataObj.minOrder = parseInt(minorder);
    if (maxdiscount !== "") finalDataObj.maxDiscount = parseInt(maxdiscount);
    if (orderNo !== "") finalDataObj.orderNo = parseInt(orderNo);
    finalDataObj.serviceId = service;
    if (validfrom !== "")
      finalDataObj.validFrom = new Date(validfrom).toISOString();
    if (validto !== "") finalDataObj.validTo = new Date(validto).toISOString();

    finalDataObj.isPercentage = isPercentage;
    finalDataObj.isActive = isActive;
    finalDataObj.hide = isHide;
    finalDataObj.gender = gender;
    finalDataObj.isServicePromo = isServicePromo;
    console.log("below is the added promo code");
    console.log(finalDataObj);
    addPromocode({
      variables: {
        data: finalDataObj,
      },
    })
      .then((res) => {
        console.log("add promocode res is");
        console.log(res);
        if (res.data.addPromocode.status === "SUCCESS") {
          window.location.reload();
          // window.location = "/admin/promocode";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  if (loading || !isFetched) return <Loader />;
  return (
    <>
      <div>
        {isFetched && (
          <div className="flex flex-col py-3 px-2 space-y-5">
            <h3 className="text-[#FBB033] font-be-jost-pro font-bold text-2xl">
              Add Promo Code
            </h3>
            <h3
              className="w-full flex items-center justify-end cursor-pointer text-white"
              onClick={() => setOpenModal(false)}
            >
              <p className="w-6 h-6">×</p>
            </h3>
            <div className="w-full flex flex-col space-y-8">
              <input
                type="text"
                placeholder="Name*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Type*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={type}
                onChange={(e) => setType(e.target.value)}
              />
              <select
                placeholder="Category*"
                className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none ${
                  gender === "" ? " text-[#9A9A9A]" : "text-white"
                }`}
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="" disabled hidden>
                  Select Gender*
                </option>

                {genderList.map((item, i) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
              <input
                type="number"
                placeholder="Discount*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={discount}
                onChange={(e) => setdiscount(e.target.value)}
              />
              <input
                type="text"
                placeholder="PROMO Code*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={promocode}
                onChange={(e) => setPromocode(e.target.value)}
              />
              <input
                type="text"
                placeholder="Description*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <input
                type="text"
                placeholder="Users*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={users}
                onChange={(e) => setUsers(e.target.value)}
              />
              <input
                type="number"
                placeholder="Minimum Order*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={minorder}
                onChange={(e) => setMinOrder(e.target.value)}
              />
              <input
                type="number"
                placeholder="Maximum Discount*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white"
                value={maxdiscount}
                onChange={(e) => setMaxDiscount(e.target.value)}
              />
              <input
                type="number"
                placeholder="Order No*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white"
                value={orderNo}
                onChange={(e) => setorderNo(e.target.value)}
              />

              <select
                className={`px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white `}
                value={service}
                onChange={(e) => setService(e.target.value)}
              >
                <p className="w-6 h-6">×</p>
                <div className="w-full flex flex-col space-y-8"></div>
                <input
                  type="text"
                  placeholder="Name*"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Type*"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Discount*"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                  value={discount}
                  onChange={(e) => setdiscount(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="PROMO Code*"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                  value={promocode}
                  onChange={(e) => setPromocode(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Description*"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Users*"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                  value={users}
                  onChange={(e) => setUsers(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Minimum Order*"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                  value={minorder}
                  onChange={(e) => setMinOrder(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Maximum Discount*"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white"
                  value={maxdiscount}
                  onChange={(e) => setMaxDiscount(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Order No*"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white"
                  value={orderNo}
                  onChange={(e) => setorderNo(e.target.value)}
                />

                <option value="" disabled>
                  Service*
                </option>

                {/* <option value="true">Yes</option>
                  <option value="false">No</option> */}
                {serviceList.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>

              <div className="relative">
                {!validfrom && (
                  <label className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm pointer-events-none">
                    Valid from date
                  </label>
                )}

                <input
                  type="text"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white w-full"
                  value={validfrom}
                  onFocus={(e) => {
                    e.target.type = "date";
                    e.target.showPicker();
                  }}
                  onBlur={(e) => {
                    if (!validfrom) e.target.type = "text";
                  }}
                  onChange={(e) => setValidFromDate(e.target.value)}
                />
              </div>

              <div className="relative ">
                {!validto && (
                  <label className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm pointer-events-none">
                    Valid To Date
                  </label>
                )}

                <input
                  type="text"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white w-full"
                  value={validto}
                  onFocus={(e) => {
                    e.target.type = "date";
                    e.target.showPicker();
                  }}
                  onBlur={(e) => {
                    if (!validto) e.target.type = "text";
                  }}
                  onChange={(e) => setValidToDate(e.target.value)}
                />
              </div>

              <div className="flex items-center justify-between px-3 py-2 border-b-2 border-b-[#E0E0E0]">
                <span className="text-white text-sm">IsPercentage</span>
                <Switch
                  checked={isPercentage}
                  onChange={(e) => setIsPercentage(e)}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={18}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor="#ffffff"
                  offHandleColor="#fbb033"
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={16}
                  width={36}
                  className="react-switch flex-shrink-0"
                />
              </div>

              <div className=" flex items-center justify-between px-3 py-2 border-b-2 border-b-[#E0E0E0]">
                <span className="text-white text-sm">IsActive</span>
                <Switch
                  checked={isActive}
                  onChange={(e) => setIsActive(e)}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={18}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor="#ffffff"
                  offHandleColor="#fbb033"
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={16}
                  width={36}
                  className="react-switch flex-shrink-0"
                />
              </div>

              <div className=" flex items-center justify-between px-3 py-2 border-b-2 border-b-[#E0E0E0]">
                <span className="text-white text-sm">IsHide</span>
                <Switch
                  checked={isHide}
                  onChange={(e) => setIsHide(e)}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={18}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor="#ffffff"
                  offHandleColor="#fbb033"
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={16}
                  width={36}
                  className="react-switch flex-shrink-0"
                />
              </div>

              <div className=" flex items-center justify-between px-3 py-2 border-b-2 border-b-[#E0E0E0]">
                <span className="text-white text-sm">IsServicePromo</span>
                <Switch
                  checked={isServicePromo}
                  onChange={(e) => setIsServicePromo(e)}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={18}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor="#ffffff"
                  offHandleColor="#fbb033"
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={16}
                  width={36}
                  className="react-switch flex-shrink-0"
                />
              </div>

              <div className="flex items-center justify-center">
                <button
                  className="bg-[#FBB034] text-[#202125] py-2 px-6 rounded-md font-poppins text-sm font-bold"
                  onClick={handleSubmit}
                >
                  Add Promo Code
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AddPromoModal;
