import React, { useState, useEffect } from "react";
import "../../index.css";
import { gql, useMutation, useQuery } from "@apollo/client";
import Switch from "react-switch";
import Loader from "../../Loader";
const UPDATE_PROMOCODE = gql`
  mutation updatePromocode($data: UpdatePromocodeInput!) {
    updatePromocode(data: $data) {
      status
      message
    }
  }
`;

const GET_SERVICES = gql`
  query {
    getAdminService {
      services {
        id
        name
      }
    }
  }
`;

const CodeManagementForm = ({ setEditCodeManagementForm, editData }) => {
  // const [updatePromocodeMutation, { loading, error }] = useMutation(UPDATE_PROMOCODE);
  const [updatePromocode, { updateloading, updateerror }] =
    useMutation(UPDATE_PROMOCODE);
  const { data, loading, error } = useQuery(GET_SERVICES);
  console.log(editData);
  const [isFetched, setIsFetched] = useState(false);
  const [name, setName] = useState(editData.name || "");
  const [type, setType] = useState(editData.type || "");
  const [discount, setdiscount] = useState(
    typeof editData.discount === "number" ? editData.discount : ""
  );
  const [promocode, setPromocode] = useState(editData.promocode || "");
  const [description, setDescription] = useState(editData.description || "");
  const [users, setUsers] = useState(
    typeof editData.users === "number" ? editData.users : ""
  );
  const [minorder, setMinOrder] = useState(
    typeof editData.minOrder === "number" ? editData.minOrder : ""
  );
  const [maxdiscount, setMaxDiscount] = useState(
    typeof editData.maxDiscount === "number" ? editData.maxDiscount : ""
  );

  console.log(editData)
  const [gender, setGender] = useState(editData.gender ?? "");
  const genderList = ["Male", "Female"];
  const [orderNo, setorderNo] = useState(
    typeof editData.orderNo === "number" ? editData.orderNo : ""
  );

  const [validfrom, setValidFromDate] = useState(
    editData.validFrom
      ? new Date(Number(editData.validFrom)).toISOString().slice(0, 10)
      : ""
  );
  const [validto, setValidToDate] = useState(
    editData.validTo
      ? new Date(Number(editData.validTo)).toISOString().slice(0, 10)
      : ""
  );

  const [service, setService] = useState(editData.serviceId || "");
  const [serviceList, setServiceList] = useState([]);

  const [isPercentage, setIsPercentage] = useState(
    editData.isPercentage ?? true
  );
  const [isActive, setIsActive] = useState(editData.isActive ?? true);
  const [isHide, setIsHide] = useState(editData.hide ?? true);
  const [isServicePromo, setIsServicePromo] = useState(
    editData.isServicePromo ?? true
  );

  useEffect(() => {
    if (data?.getAdminService?.services) {
      console.log("get service called");
      console.log(data.getAdminService.services);
      setServiceList(data.getAdminService.services);
      setIsFetched(true);
    }
  }, [data]);

  // const handleClick = async () => {
  //   try {
  //     const { data } = await deleteCustomerMutation({variables:{
  //       id: editData.id,name:name,type:type,promocode:promoCode,discount:parseInt(off)
  //     }})
  //     setData(data)
  //   }
  //   catch (e) {
  //     console.log(e);
  //   }
  // }
  const handleSubmit = (e) => {
    e.preventDefault();
    let finalDataObj = {};
    finalDataObj.id = editData.id;
    finalDataObj.name = name;
    finalDataObj.type = type;
    if (discount !== "") finalDataObj.discount = parseInt(discount);
    finalDataObj.promocode = promocode;
    finalDataObj.description = description;
    if (users !== "") finalDataObj.users = parseInt(users);
    if (minorder !== "") finalDataObj.minOrder = parseInt(minorder);
    if (maxdiscount !== "") finalDataObj.maxDiscount = parseInt(maxdiscount);
    if (orderNo !== "") finalDataObj.orderNo = parseInt(orderNo);
    finalDataObj.serviceId = service;

    if (validfrom !== "")
      finalDataObj.validFrom = new Date(validfrom).toISOString();
    if (validto !== "") finalDataObj.validTo = new Date(validto).toISOString();

    finalDataObj.isPercentage = isPercentage;
    finalDataObj.isActive = isActive;
    finalDataObj.hide = isHide;
    finalDataObj.gender = gender;

    finalDataObj.isServicePromo = isServicePromo;

    console.log(finalDataObj);
    updatePromocode({
      variables: {
        data: finalDataObj,
      },
    })
      .then((res) => {
        console.log("add promocode res is");
        console.log(res);
        if (res.data.updatePromocode.status === "SUCCESS") {
          window.location.reload();
          // window.location = "/admin/promocode";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loading || !isFetched) return <Loader />;

  return (
    <>
      <div>
        {isFetched && (
          <div className="flex flex-col py-3 px-2 space-y-5">
            <h3 className="text-[#FBB033] font-be-jost-pro font-bold text-2xl">
              Edit Promo Code
            </h3>
            <h3
              className="w-full flex items-center justify-end cursor-pointer text-white"
              onClick={() => setEditCodeManagementForm(false)}
            >
              <p className="w-6 h-6">×</p>
            </h3>
            <div className="w-full flex flex-col space-y-8">
              <input
                type="text"
                placeholder="Name*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Type*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={type}
                onChange={(e) => setType(e.target.value)}
              />
              <select
                placeholder="Category*"
                className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none ${
                  gender === "" ? " text-[#9A9A9A]" : "text-white"
                }`}
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="" disabled hidden>
                  Select Gender*
                </option>

                {genderList.map((item, i) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
              <input
                type="number"
                placeholder="Discount*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={discount}
                onChange={(e) => setdiscount(e.target.value)}
              />
              <input
                type="text"
                placeholder="PROMO Code*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={promocode}
                onChange={(e) => setPromocode(e.target.value)}
              />
              <input
                type="text"
                placeholder="Description*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <input
                type="number"
                placeholder="Users*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={users}
                onChange={(e) => setUsers(e.target.value)}
              />
              <input
                type="number"
                placeholder="Minimum Order*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white "
                value={minorder}
                onChange={(e) => setMinOrder(e.target.value)}
              />
              <input
                type="number"
                placeholder="Maximum Discount*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white"
                value={maxdiscount}
                onChange={(e) => setMaxDiscount(e.target.value)}
              />

              <input
                type="number"
                placeholder="Order No*"
                className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white"
                value={orderNo}
                onChange={(e) => setorderNo(e.target.value)}
              />
              <select
                className={`px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white `}
                value={service}
                onChange={(e) => setService(e.target.value)}
              >
                <option value="" disabled>
                  Service*
                </option>

                {/* <option value="true">Yes</option>
               <option value="false">No</option> */}
                {serviceList.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>

              <div className="relative">
                {!validfrom && (
                  <label className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm pointer-events-none">
                    Valid from date
                  </label>
                )}

                <input
                  type="text"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white w-full"
                  value={validfrom}
                  onFocus={(e) => {
                    e.target.type = "date";
                    e.target.showPicker();
                  }}
                  onBlur={(e) => {
                    if (!validfrom) e.target.type = "text";
                  }}
                  onChange={(e) => setValidFromDate(e.target.value)}
                />
              </div>

              <div className="relative ">
                {!validto && (
                  <label className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm pointer-events-none">
                    Valid To Date
                  </label>
                )}

                <input
                  type="text"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white w-full"
                  value={validto}
                  onFocus={(e) => {
                    e.target.type = "date";
                    e.target.showPicker();
                  }}
                  onBlur={(e) => {
                    if (!validto) e.target.type = "text";
                  }}
                  onChange={(e) => setValidToDate(e.target.value)}
                />
              </div>

              <div className="flex items-center justify-between px-3 py-2 border-b-2 border-b-[#E0E0E0]">
                <span className="text-white text-sm">IsPercentage</span>
                <Switch
                  checked={isPercentage}
                  onChange={(e) => setIsPercentage(e)}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={18}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor="#ffffff"
                  offHandleColor="#fbb033"
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={16}
                  width={36}
                  className="react-switch flex-shrink-0"
                />
              </div>

              <div className=" flex items-center justify-between px-3 py-2 border-b-2 border-b-[#E0E0E0]">
                <span className="text-white text-sm">IsActive</span>
                <Switch
                  checked={isActive}
                  onChange={(e) => setIsActive(e)}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={18}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor="#ffffff"
                  offHandleColor="#fbb033"
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={16}
                  width={36}
                  className="react-switch flex-shrink-0"
                />
              </div>

              <div className=" flex items-center justify-between px-3 py-2 border-b-2 border-b-[#E0E0E0]">
                <span className="text-white text-sm">IsHide</span>
                <Switch
                  checked={isHide}
                  onChange={(e) => setIsHide(e)}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={18}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor="#ffffff"
                  offHandleColor="#fbb033"
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={16}
                  width={36}
                  className="react-switch flex-shrink-0"
                />
              </div>

              <div className=" flex items-center justify-between px-3 py-2 border-b-2 border-b-[#E0E0E0]">
                <span className="text-white text-sm">IsServicePromo</span>
                <Switch
                  checked={isServicePromo}
                  onChange={(e) => setIsServicePromo(e)}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={18}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor="#ffffff"
                  offHandleColor="#fbb033"
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={16}
                  width={36}
                  className="react-switch flex-shrink-0"
                />
              </div>

              <div className="flex items-center justify-center">
                <button
                  className="bg-[#FBB034] text-[#202125] py-2 px-6 rounded-md font-poppins text-sm font-bold"
                  onClick={handleSubmit}
                >
                  Edit promoCode
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CodeManagementForm;
